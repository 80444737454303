import * as React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import "twin.macro";
import { StaticImage } from "gatsby-plugin-image";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

export default function cooking() {
  return (
    <Layout>
      <SEO title="Podcast" />

      <div
        className="fusion-column-wrapper"
        data-bg-url=""
        tw="flex flex-col justify-center text-center relative text-black"
      >
        <h1
          data-fontsize="54"
          data-lineheight="62.64px"
          tw="text-primary font-bold text-4xl md:text-6xl my-8"
        >
          PODCAST
        </h1>

        <div className="" tw="font-bold text-xl md:text-2xl px-4 mb-4 md:mb-24">
          <h3 data-fontsize="22" data-lineheight="28.6px" tw="mb-4">
            Küchenexperten verraten im Podcast Tipps und Tricks rund um die
            Themen Küchenkauf und Küchenplanung.
          </h3>
          <h3 tw="">
            Wir erstellen Ihren Podcast, individuell auf Ihre Marke
            zugeschnitten.
          </h3>
        </div>
        <div className="" tw="flex justify-center w-1/2 mx-auto mb-4 md:mb-24">
          <AudioPlayer
            autoPlay
            src="/audio/sample_kuchenflusterer.mp3"
            onPlay={(e) => console.log("onPlay")}
            // other props here
          />
        </div>
        {/* picture */}
        <div className="imageframe-align-center" tw="mx-4 md:mx-8">
          <div className="" tw="flex justify-center">
            <a
              href="https://www.reddy.de/podcast/"
              target="_self"
              aria-label="RKPodcast"
            >
              <StaticImage
                src="../../images/RK_Podcast_2.jpg"
                width="800"
                height="800"
                alt="Reddy Küchen Podcast"
              />
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}
